





































import BaseStep from './BaseStep'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import { helper } from '@/services'
import { EnergySelection } from '@/models'

@Component({
  name: 'prosumer-step',
})
export default class ProsumerStep extends BaseStep {
  //#region [Data]
  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null
  @State('isProsumer', { namespace: 'protectedCustomerRequest/prosumer' })
  public isProsumerState!: number | null
  public isProsumer: number | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'protectedCustomerRequest/prosumer' })
  public store!: (value: number | null) => void
  //#endregion

  //#region [Computed]
  public get isElectricityRequest(): boolean {
    return this.energySelectionState !== null &&
      (this.energySelectionState.electricityAndGas || this.energySelectionState.electricity)
  }

  //#endregion

  //#region [Method]
  public mounted() {
    if (this.isElectricityRequest) {
      this.setIsBack(false)
      this.isProsumer = helper.clone(this.isProsumerState)
    } else {
      if (this.isBack) {
        this.previous()
      } else {
        this.setIsBack(false)
        this.isProsumer = null
        this.next()
      }
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepProsumer_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        IsProsumer: this.isProsumer?.toString() ?? '',
      })
    }

    this.store(this.isProsumer)
  }

  //#endregion
}
