var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lib__field",staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"lib__field lib__field--checkbox"},[_c('label',{staticClass:"lib__field__label"},[_c('span',{staticClass:"lib__field__label__icon"},[(_vm.IsGas)?_c('img',{attrs:{"src":require('@ores/css-library/assets/img/icons/gas.svg'),"alt":"Gas"}}):_c('img',{attrs:{"src":require('@ores/css-library/assets/img/icons/electricity.svg'),"alt":"Electricity"}})]),_c('span',[_vm._v(_vm._s(_vm.value.ean))])])]),_c('div',{staticClass:"lib__card lib__bg-white"},[_vm._l((_vm.value.meterInfos),function(meterInfo,idx){return [_c('MeterBox',{attrs:{"isGas":_vm.IsGas,"value":meterInfo},on:{"input":function($event){return _vm.setMeterValue($event, idx)}}}),(!_vm.onlyIndex)?[(!_vm.IsGas)?_c('div',[_c('h4',{staticClass:"lib__h4"},[_vm._v(_vm._s(_vm.$t('protectedCustomerRequest.meterReading.whatTariffElectricity')))]),_c('ValidationProvider',{attrs:{"rules":{ 'required': true},"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"lib__field",class:[failed && 'lib__field--error', passed && 'lib__field--valid']},[_c('div',{staticClass:"lib__tiles lib__tiles--inline"},[_c('TileRadio',{key:"simple",attrs:{"group":'tariff-' + meterInfo.meterId,"label":_vm.$t('protectedCustomerRequest.meterReading.simpleTariff'),"item":"simple"},model:{value:(meterInfo.tariffType),callback:function ($$v) {_vm.$set(meterInfo, "tariffType", $$v)},expression:"meterInfo.tariffType"}}),_c('TileRadio',{key:"dual",attrs:{"group":'tariff-' + meterInfo.meterId,"label":_vm.$t('protectedCustomerRequest.meterReading.dualTariff'),"item":"dual"},model:{value:(meterInfo.tariffType),callback:function ($$v) {_vm.$set(meterInfo, "tariffType", $$v)},expression:"meterInfo.tariffType"}}),_c('TileRadio',{key:"nightExclusive",attrs:{"group":'tariff-' + meterInfo.meterId,"label":_vm.$t('protectedCustomerRequest.meterReading.nightExclusive'),"item":"nightExclusive"},model:{value:(meterInfo.tariffType),callback:function ($$v) {_vm.$set(meterInfo, "tariffType", $$v)},expression:"meterInfo.tariffType"}})],1),_c('error-message',{attrs:{"error":errors[0]}})],1)]}}],null,true)})],1):_vm._e(),_c('div',[_c('h4',{staticClass:"lib__h4"},[_vm._v(" "+_vm._s(_vm.IsGas ? _vm.$t('protectedCustomerRequest.meterReading.isGasBudgetMeter') : _vm.$t('protectedCustomerRequest.meterReading.isElectricBudgetMeter'))+" ")]),_c('ValidationProvider',{attrs:{"rules":{ 'required': true},"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"lib__field",class:[failed && 'lib__field--error', passed && 'lib__field--valid']},[_c('div',{staticClass:"lib__tiles lib__tiles--inline"},[_c('TileRadio',{key:1,attrs:{"group":'budget-meter-' + meterInfo.meterId,"item":1,"label":_vm.$t('common.yes')},model:{value:(meterInfo.isCustomBudgetMeter),callback:function ($$v) {_vm.$set(meterInfo, "isCustomBudgetMeter", $$v)},expression:"meterInfo.isCustomBudgetMeter"}}),_c('TileRadio',{key:0,attrs:{"group":'budget-meter-' + meterInfo.meterId,"item":0,"label":_vm.$t('common.no')},model:{value:(meterInfo.isCustomBudgetMeter),callback:function ($$v) {_vm.$set(meterInfo, "isCustomBudgetMeter", $$v)},expression:"meterInfo.isCustomBudgetMeter"}})],1),_c('error-message',{attrs:{"error":errors[0]}})],1)]}}],null,true)})],1)]:_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }