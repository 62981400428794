
















import BaseStep from './BaseStep'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import { EnergySelection } from '@/models'
import EnergySelector from '@/components/inputs/energy/EnergySelector.vue'
import { helper } from '@ores/vue-library'

@Component({
  name: 'energy-step',
  components: { EnergySelector },
})
export default class EnergyStep extends BaseStep {
  //#region [Data]
  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null

  public energySelection: EnergySelection | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'protectedCustomerRequest/energy' })
  public store!: (value: EnergySelection | null) => void
  //#endregion

  //#region [Computed]
  public get energies(): string[] {
    return ['electricityAndGas', 'electricity', 'gas']
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.energySelection = helper.clone(this.energySelectionState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepEnergy_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
      })
    }

    this.store(this.energySelection)
  }

  //#endregion
}
