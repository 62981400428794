

























































import BaseStep from './BaseStep'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import { helper } from '@/services'
import { CustomerState } from '@/store/protected-customer-request/customer/types'
import { Watch } from 'vue-property-decorator'

@Component({
  name: 'customer-step',
})
export default class CustomerStep extends BaseStep {
  //#region [Data]
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null
  public isCustomer: number | null = null

  @State('stayCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public stayCustomerState!: number | null
  public stayCustomer: number | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'protectedCustomerRequest/customer' })
  public store!: (value: CustomerState | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.$appInsights.trackEvent('ProtectedCustomerRequest_StartForm', {
      Segment: 'ProtectedCustomerRequest',
      WebRef: this.requestId
    })

    this.setIsBack(false)
    this.setNextButtonStatus(true)
    this.isCustomer = helper.clone(this.isCustomerState)
    this.stayCustomer = helper.clone(this.stayCustomerState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepCustomer_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        IsCustomer: this.isCustomer!.toString(),
        StayCustomer: this.stayCustomer?.toString() ?? '',
      })
    }

    this.store({ isCustomer: this.isCustomer, stayCustomer: this.stayCustomer })
  }

  //#endregion

  //#region [Watch]
  @Watch('isCustomer')
  public onIsCustomerChanged(newVal: number) {
    if (newVal !== 1) {
      this.stayCustomer = null
    }
  }

  //#endregion
}
