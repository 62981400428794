














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'collapse-info'
})
export default class CollapseInfo extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly title!: string;
  //#endregion

  //#region [Data]
  public collapsed: boolean = true;
  //#endregion
}
