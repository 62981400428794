






































import { Component } from 'vue-property-decorator'
import BaseStep from '@/components/steps/protected-customer-request/BaseStep'
import ContactBaseForm from '@/components/inputs/contact/ContactBaseForm.vue'
import AddressForm from '@/components/inputs/contact/AddressForm.vue'
import ContactPersonForm from '@/components/inputs/contact/ContactPersonForm.vue'
import { ContactInfo, GridAccessPoint } from '@/models'
import { contactService, helper } from '@/services'
import { Mutation, State } from 'vuex-class'

@Component({
  name: 'contact-info-step',
  components: {
    ContactBaseForm,
    AddressForm,
    ContactPersonForm,
  },
})
export default class ContactStep extends BaseStep {
  //#region [Data]
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null
  @State('gridAccessPointInfo', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointInfoState!: GridAccessPoint | null
  @State('contactInfo', { namespace: 'protectedCustomerRequest/contactInfo' })
  public contactInfoState!: ContactInfo | null
  public contactInfo: ContactInfo = contactService.createContactInfo()
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'protectedCustomerRequest/contactInfo' })
  private store!: (index: ContactInfo | null) => void
  //#endregion

  //#region [Computed]
  public get consumptionAddress() {
    return this.gridAccessPointInfoState?.consumptionAddress
  }

  public get showCustomerRef(): boolean {
    return this.isCustomerState === 1
  }

  public get contactAddressType(): number {
    if (this.contactInfo.addOtherContact) {
      return 2
    }
    return this.contactInfo.useOtherAddress ? 1 : 0
  }

  public set contactAddressType(value: number) {
    switch (value) {
      case 0:
        this.contactInfo.useOtherAddress = false
        this.contactInfo.otherAddress = null
        this.contactInfo.addOtherContact = false
        this.contactInfo.otherContact = null
        break
      case 1:
        this.contactInfo.useOtherAddress = true
        this.contactInfo.otherAddress = contactService.createContactAddress()
        this.contactInfo.addOtherContact = false
        this.contactInfo.otherContact = null
        break
      case 2:
        this.contactInfo.useOtherAddress = false
        this.contactInfo.otherAddress = null
        this.contactInfo.addOtherContact = true
        this.contactInfo.otherContact = contactService.createContactPerson()
        break
    }
  }

  private get formattedAddress() {
    let str = ''
    if (this.consumptionAddress) {
      str += this.consumptionAddress.houseNumber
      str += ' ' + this.consumptionAddress.streetName
      if (this.consumptionAddress.complement) {
        str += ' ' + this.consumptionAddress.complement
      }
      if (this.consumptionAddress.complementBox) {
        str += ' ' + this.consumptionAddress.complementBox
      }
      if (this.consumptionAddress.floorNumber) {
        str += ` (${this.$t(`common.identification.floor`).toString()}: ${this.consumptionAddress.floorNumber})`
      }
      str += '<br>' + this.consumptionAddress.zipCode
      str += ' ' + this.consumptionAddress.cityName
      return str
    }
    return ''
  }

  //#endregion

  //#region [Watch]
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.contactInfoState !== null) {
      this.contactInfo = helper.clone(this.contactInfoState)
      if (this.isCustomerState === 0 && this.contactInfo.contactBase) {
        this.contactInfo.contactBase.customerRef = null
      }
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepContact_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
      })
    }

    if (this.contactInfo.contactBase) {
      this.contactInfo.contactBase.contactType = 'individual'
    }

    this.store(this.contactInfo)
  }

  //#endregion
}
