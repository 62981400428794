export enum RegisterType {
  NotUsed = 'notUsed'
  , DayHours = 'dayHours'
  , DayPeakHours = 'dayPeakHours'
  , NightExclusive = 'nightExclusive'
  , NightHours = 'nightHours'
  , NightPeakHours = 'nightPeakHours'
  , OffPeakHours = 'offPeakHours'
  , PeakHours = 'peakHours'
  , TotalHours = 'totalHours'
}
