





























import BaseStep from './BaseStep'
import { Component } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import GridAccessPointBox from '@/components/inputs/protected-customer-request/meter-reading/GridAccessPointBox.vue'
import { helper } from '@ores/vue-library'
import { GridAccessPoint } from '@/models/protected-customer-request'

@Component({
  components: {
    GridAccessPointBox,
  },
  name: 'meter-reading-step',
})
export default class MeterReadingStep extends BaseStep {
  //#region [Data]
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null
  @State('gridAccessPointElectricity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public gridAccessPointElectricityState!: GridAccessPoint | null
  @State('gridAccessPointGas', { namespace: 'protectedCustomerRequest/oldAddress' })
  public gridAccessPointGasState!: GridAccessPoint | null
  @State('movingDate', { namespace: 'protectedCustomerRequest/oldAddressMeterReading' })
  public readonly movingDateState!: Date | null

  public movingDate: Date | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_MOVING_DATE', { namespace: 'protectedCustomerRequest/oldAddressMeterReading' })
  protected readonly storeMovingDate!: (data: Date | null) => void
  @Mutation('STORE_GRID_ACCESS_POINT_ELECTRICITY', { namespace: 'protectedCustomerRequest/oldAddress' })
  protected readonly storeGridAccessPointElectricity!: (data: GridAccessPoint) => void
  @Mutation('STORE_GRID_ACCESS_POINT_GAS', { namespace: 'protectedCustomerRequest/oldAddress' })
  protected readonly storeGridAccessPointGas!: (data: GridAccessPoint) => void
  //#endregion

  //#region [Method]
  public async mounted(): Promise<void> {
    if (this.isCustomerState === 0) {
      if (this.isBack) {
        await this.previous()
      } else {
        this.setIsBack(false)
        await this.next()
      }
    } else {
      this.setIsBack(false)
      if (this.movingDateState) {
        this.movingDate = helper.clone(this.movingDateState)
      }
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepOldAddressMeterReading_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        IsCustomer: (this.isCustomerState === 1).toString()
      })
    }

    this.storeMovingDate(this.movingDate)
  }

  //#endregion
}
