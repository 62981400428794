





















































































import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import MeterBox from '@/components/inputs/protected-customer-request/meter-reading/MeterBox.vue'
import { GridAccessPoint, Meter } from '@/models/protected-customer-request'
import { EnergyType, TariffType } from '@/models/common'

@Component({
  components: {
    MeterBox,
  },
})
export default class GridAccessPointBox extends Vue {
  @Prop({ required: true }) public readonly value!: GridAccessPoint
  @Prop({ type: Boolean, required: false, default: false }) public readonly onlyIndex!: boolean

  public get IsGas(): boolean {
    return this.EnergyType === EnergyType.gas
  }

  public get EnergyType(): EnergyType {
    return this.value.energyType
  }

  public mounted(): void {
    for (const meter of this.value.meterInfos) {
      if (meter.tariffType === null) {
        meter.tariffType =
          meter.isSimple ? TariffType.Simple :
            meter.isNightExclusive ? TariffType.NightExclusive :
              meter.isDayAndNight ? TariffType.Dual : null
      }
    }
  }

  public setMeterValue(data: Meter, idx: number): void {
    this.value.meterInfos[idx] = data
  }
}
