



















































































import { Form } from '@/views/forms/Form'
import { Action, State } from 'vuex-class'
import { Component, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { ApiErrorNotification, ContactInfo, EnergySelection, FileInfo, StepDefinition } from '@/models'
import ApiErrors from '@/components/display/ApiErrors.vue'
import StepActions from '@/components/shared/StepActions.vue'
import CustomerStep from '@/components/steps/protected-customer-request/CustomerStep.vue'
import EnergyStep from '@/components/steps/protected-customer-request/EnergyStep.vue'
import ProsumerStep from '@/components/steps/protected-customer-request/ProsumerStep.vue'
import IdentificationStep from '@/components/steps/protected-customer-request/IdentificationStep.vue'
import MeterReadingStep from '@/components/steps/protected-customer-request/MeterReadingStep.vue'
import OldAddressEnergyStep from '@/components/steps/protected-customer-request/OldAdressEnergyStep.vue'
import OldAddressStep from '@/components/steps/protected-customer-request/OldAddressStep.vue'
import OldAddressMeterReadingStep from '@/components/steps/protected-customer-request/OldAddressMeterReadingStep.vue'
import AttestationStep from '@/components/steps/protected-customer-request/AttestationStep.vue'
import ContactStep from '@/components/steps/protected-customer-request/ContactStep.vue'
import OwnerStep from '@/components/steps/protected-customer-request/OwnerStep.vue'
import SummaryStep from '@/components/steps/protected-customer-request/SummaryStep.vue'
import SendConfirmation from '@/components/display/SendConfirmation.vue'

@Component({
  name: 'protectedCustomerRequest',
  components: {
    ApiErrors,
    CustomerStep,
    EnergyStep,
    ProsumerStep,
    IdentificationStep,
    MeterReadingStep,
    OldAddressStep,
    OldAddressEnergyStep,
    OldAddressMeterReadingStep,
    AttestationStep,
    ContactStep,
    OwnerStep,
    SummaryStep,
    SendConfirmation,
    StepActions,
  },
})
export default class ProtectedCustomerRequest extends Form {
  //#region [Data]
  @State('apiErrors', { namespace: 'apiErrors' })
  public readonly apiErrors!: ApiErrorNotification | null
  @State('requestId', { namespace: 'protectedCustomerRequest' })
  public readonly requestId!: string

  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null

  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null

  @State('eanCodeElectricity', { namespace: 'protectedCustomerRequest/identification' })
  public eanCodeElectricityState!: string | null
  @State('eanCodeGas', { namespace: 'protectedCustomerRequest/identification' })
  public eanCodeGasState!: string | null

  @State('energySelection', { namespace: 'protectedCustomerRequest/oldAddressEnergy' })
  public readonly oldAddressEnergySelectionState!: EnergySelection | null

  @State('eanCodeElectricity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public oldAddressEanCodeElectricityState!: string | null
  @State('eanCodeGas', { namespace: 'protectedCustomerRequest/oldAddress' })
  public oldAddressEanCodeGasState!: string | null

  @State('attachments', { namespace: 'protectedCustomerRequest/attestation' })
  public attachmentsState!: FileInfo[]

  @State('contactInfo', { namespace: 'protectedCustomerRequest/contactInfo' })
  public contactInfoState!: ContactInfo | null


  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('customer', 1),
    new StepDefinition('actualAddress', 2,
      [
        new StepDefinition('', 3),
        new StepDefinition('', 4),
        new StepDefinition('', 5),
      ]),
    new StepDefinition('oldAddress', 6,
      [
        new StepDefinition('', 7),
        new StepDefinition('', 8),
      ]),
    new StepDefinition('attestation', 9),
    new StepDefinition('contact', 10,
      [new StepDefinition('', 11)]),
    new StepDefinition('summary', 12),
    new StepDefinition('confirmation', 13),
  ] as StepDefinition[]

  @Action('CLEAR_STEPS', { namespace: 'protectedCustomerRequest' })
  public clearSteps!: (step: number) => Promise<void>

  //#region [Computed]
  public get multipleSend(): boolean {
    return this.contactInfoState !== null &&
      this.contactInfoState.contactBase !== null &&
      this.contactInfoState.addOtherContact === true &&
      this.contactInfoState.otherContact !== null
  }

  public get sendConfirmationEmail(): string {
    let email: string = ''
    if (this.contactInfoState) {
      if (this.contactInfoState.contactBase) {
        email += this.contactInfoState.contactBase.email
      }
      if (this.contactInfoState.addOtherContact && this.contactInfoState.otherContact) {
        email += ', ' + this.contactInfoState.otherContact.email
      }
    }

    return email
  }

  public get checkShowFaq() {
    return this.currentStep === 5 || this.currentStep === 8
  }

  get hasNext(): boolean {
    switch (this.currentStep) {
      default:
        return this.currentStep < this.lastStep
    }
  }

  get hasPrevious(): boolean {
    return this.currentStep > 1 && this.currentStep < 13
  }

  get nextLabel(): string {
    switch (this.currentStep) {
      case 12:
        return this.$t('common.send').toString()
      default:
        return this.$t('common.nextButton').toString()
    }
  }

  //#region [Method]
  public getStepComponent(step: number): Step | null {
    switch (step) {
      case 1:
        return this.$refs.step1 as Step
      case 2:
        return this.$refs.step2 as Step
      case 3:
        return this.$refs.step3 as Step
      case 4:
        return this.$refs.step4 as Step
      case 5:
        return this.$refs.step5 as Step
      case 6:
        return this.$refs.step6 as Step
      case 7:
        return this.$refs.step7 as Step
      case 8:
        return this.$refs.step8 as Step
      case 9:
        return this.$refs.step9 as Step
      case 10:
        return this.$refs.step10 as Step
      case 11:
        return this.$refs.step11 as Step
      case 12:
        return this.$refs.step12 as Step
      default:
        return null
    }
  }

  //#endregion

  public mounted(): void {
    sessionStorage.clear()
    this.onLangChanged(this.lang)
    this.currentStep = 1
    this.lastStepSeen = 1
    this.stepToGoAfterValidation = 12
    this.stepsToValidate = [1, 2, 4, 6, 7]
  }

  public beforeCreate(): void {
    document.title = this.$t('protectedCustomerRequest.formTitle').toString()
  }

  public created() {
    this.CheckMaintenance('protected-customer')
  }

  //#endregion

  //#region [Watch]
  @Watch('isCustomerState')
  public async onIsCustomerStateChanged(newValue: number | null, oldValue: number | null) {
    if (newValue === null || oldValue === null) {
      return
    }

    if (newValue === 0 && oldValue === 1) {
      await this.clearSteps(1)

      if (this.attachmentsState.length === 0) {
        this.ensureLastStep(9, false)
      }
    }

    if (newValue === 1 && oldValue === 0) {
      this.ensureLastStep(6, false)
    }
  }

  @Watch('energySelectionState', { deep: true })
  public async onEnergySelectionStateChanged(newValue: EnergySelection | null, oldValue: EnergySelection | null) {
    if (newValue === null || oldValue === null) {
      return
    }

    if ((newValue.gas && (oldValue.electricity || oldValue.electricityAndGas)) ||
      (newValue.electricity && (oldValue.gas || oldValue.electricityAndGas))) {
      await this.clearSteps(2)
      this.ensureLastStep(3, false)
    }

    if (newValue.electricityAndGas && (oldValue.gas || oldValue.electricity)) {
      this.ensureLastStep(3, false)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('eanCodeElectricityState')
  public async onEanCodeElectricityStateChanged(newValue: string | null, oldValue: string | null) {
    if (newValue !== oldValue) {
      this.ensureLastStep(5, false)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('eanCodeGasState')
  public async onEanCodeGasStateChanged(newValue: string | null, oldValue: string | null) {
    if (newValue !== oldValue) {
      this.ensureLastStep(5, false)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('oldAddressEnergySelectionState', { deep: true })
  public async onOldAddressEnergySelectionStateChanged(newVal: EnergySelection | null, oldVal: EnergySelection | null) {
    if (newVal === null || oldVal === null) {
      return
    }

    if ((newVal.gas && (oldVal.electricity || oldVal.electricityAndGas)) ||
      (newVal.electricity && (oldVal.gas || oldVal.electricityAndGas))) {
      await this.clearSteps(6)
      this.ensureLastStep(7, false)
    }

    if (newVal.electricityAndGas && (oldVal.gas || oldVal.electricity)) {
      this.ensureLastStep(7, false)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('oldAddressEanCodeElectricityState')
  public async onOldAddressEanCodeElectricityStateChanged(newValue: string | null, oldValue: string | null) {
    if (newValue === null) {
      return
    }

    if (newValue !== oldValue) {
      this.ensureLastStep(8, false)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('oldAddressEanCodeGasState')
  public async onOldAddressEanCodeGasStateChanged(newValue: string | null, oldValue: string | null) {
    if (newValue === null) {
      return
    }

    if (newValue !== oldValue) {
      this.ensureLastStep(8, false)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }


  @Watch('requestId')
  public onRequestIdChanged(): void {
    if (!this.requestId) {
      this.currentStep = 1
      this.lastStepSeen = 1
    }
  }

  //#endregion
}
