


































































































































































import { Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import ZipCodeLocalitySelector from '@/components/inputs/address/ZipCodeLocalitySelector.vue'
import ConnectionPointArea from '@/components/inputs/address/ConnectionPointArea.vue'
import Component from 'vue-class-component'
import { EnergySelection, SubCity } from '@/models'
import { Getter, Mutation, State } from 'vuex-class'
import {
  GetElectricityAndGasGridAccessPointsByEanQuery,
  GetElectricityAndGasGridAccessPointsByEanResult,
  GridAccessPoint,
} from '@/models/protected-customer-request'
import { ConsumptionAddress } from '@/models/common'
import { chatService } from '@/services'

@Component({
  name: 'GetGridAccessPointInfo',
  components: {
    ZipCodeLocalitySelector,
    ConnectionPointArea,
  },
})
export default class GetGridAccessPointInfo extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: GetGridAccessPointInfoModel
  //#endregion

  //#region [Data]
  @State('energySelection', { namespace: 'protectedCustomerRequest/oldAddressEnergy' })
  public readonly energySelectionState!: EnergySelection | null
  @Getter('httpHeaders', { namespace: 'protectedCustomerRequest' })
  public readonly httpHeaders!: any

  @State('eanCodeElectricity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public eanCodeElectricityState!: string | null
  @State('eanCodeGas', { namespace: 'protectedCustomerRequest/oldAddress' })
  public eanCodeGasState!: string | null

  public showErrorMessage: boolean = false
  public loading: boolean = false
  public whereFoundEanModalOpen: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_IDENTIFICATION_INFO', { namespace: 'protectedCustomerRequest/oldAddress' })
  public storeIdentificationInfo!: (value: {
    subCity: SubCity | null,
    eanCodeElectricity: string | null,
    eanCodeGas: string | null
  }) => void
  @Mutation('STORE_GRID_ACCESS_POINT_INFO', { namespace: 'protectedCustomerRequest/oldAddress' })
  public storeGridAccessPointInfo!: (value: GetElectricityAndGasGridAccessPointsByEanResult | null) => void
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  public clearApiErrors!: () => void
  //#endregion

  //#region [Computed]
  public get isIdentifiedAndCanContinue(): boolean {
    return this.value.isIdentified &&
      (this.value.eanCodeGas === null || this.value.gridAccessPointGas !== null) &&
      (this.value.eanCodeElectricity === null || this.value.gridAccessPointElectricity !== null)
  }

  public get computeIsIdentified(): boolean {
    return !this.eanGasNotFound && !this.eanElectricityNotFound
  }

  public get eanElectricityNotFound(): boolean {
    if (this.energySelectionState?.electricityAndGas === true || this.energySelectionState?.electricity === true) {
      return this.value.gridAccessPointElectricity === null
    }

    return false
  }

  public get eanGasNotFound(): boolean {
    if (this.energySelectionState?.electricityAndGas === true || this.energySelectionState?.gas === true) {
      return this.value.gridAccessPointGas === null
    }

    return false
  }

  public get electricityConsumptionAddress(): string | null {
    return this.value.gridAccessPointElectricity ?
      this.formatConsumptionAddress(this.value.gridAccessPointElectricity.consumptionAddress) :
      null
  }

  public get gasConsumptionAddress(): string | null {
    return this.value.gridAccessPointGas ?
      this.formatConsumptionAddress(this.value.gridAccessPointGas.consumptionAddress) :
      null
  }

  //#endregion

  //#region [Watch]
  @Watch('isIdentifiedAndCanContinue', { immediate: true })
  @Emit('canContinue')
  public isIdentifiedAndCanContinueChange(value: boolean): boolean | null {
    return value
  }

  @Watch('value.eanCodeElectricity', { deep: true })
  public onEanCodeElectricityChanged(newVal: string) {
    if (newVal === this.eanCodeElectricityState) {
      this.value.isIdentified = this.computeIsIdentified
    } else {
      this.value.isIdentified = false
    }
  }

  @Watch('value.eanCodeGas', { deep: true })
  public onEanCodeGasChanged(newVal: string) {
    if (newVal === this.eanCodeGasState) {
      this.value.isIdentified = this.computeIsIdentified
    } else {
      this.value.isIdentified = false
    }
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.value.isIdentified = this.computeIsIdentified
  }

  public async getGridAccessPointsInfo(): Promise<GetElectricityAndGasGridAccessPointsByEanResult> {
    this.value.eanCodeElectricity = this.value.eanCodeElectricity?.trim() ?? null
    this.value.eanCodeGas = this.value.eanCodeGas?.trim() ?? null

    const payload: GetElectricityAndGasGridAccessPointsByEanQuery = {
      eanElectricity: this.value.eanCodeElectricity,
      eanGas: this.value.eanCodeGas,
    }
    const response = await this.$api.post<GetElectricityAndGasGridAccessPointsByEanResult>(
      `protected-customer/ean-identification`,
      payload,
      {
        headers: {
          Authorization: sessionStorage.getItem('token')
        }
      },
    )
    return response.data
  }

  public async identify() {
    if (await this.$validatorObs.validate()) {
      try {
        this.loading = true

        const gridAccessPoints = await this.getGridAccessPointsInfo()

        this.storeGridAccessPointInfo(gridAccessPoints)

        this.value.gridAccessPointElectricity = gridAccessPoints.gridAccessPointElectricity
        this.value.gridAccessPointGas = gridAccessPoints.gridAccessPointGas
        this.value.isSameAddress = gridAccessPoints.isSameAddress

        this.storeIdentificationInfo({
          subCity: this.value.subCity,
          eanCodeElectricity: this.value.eanCodeElectricity?.trim() ?? null,
          eanCodeGas: this.value.eanCodeGas?.trim() ?? null,
        })

        this.loading = false
        this.value.isIdentified = this.computeIsIdentified
        this.showErrorMessage = true

      } catch (err) {
        this.loading = false
        this.value.isIdentified = false
        this.showErrorMessage = false
        this.value.gridAccessPointElectricity = null
        this.value.gridAccessPointGas = null
        this.storeGridAccessPointInfo(null)
      }
    }
  }

  public openChat() {
    chatService.openChat()
  }

  public formatConsumptionAddress(address: ConsumptionAddress): string {
    let str = '' + address.streetName
    if (address.houseNumber) {
      str += ' ' + address.houseNumber
    }
    if (address.complement) {
      str += ' ' + address.complement
    }
    if (address.complementBox) {
      str += ' ' + address.complementBox
    }
    if (address.floorNumber) {
      str += ` (${this.$t(`protectedCustomerRequest.identification.floor`).toString()}: ${address.floorNumber})`
    }
    str += ', ' + address.zipCode
    str += ' ' + address.cityName
    return str
  }

  //#endregion
}

export interface GetGridAccessPointInfoModel {
  subCity: SubCity | null
  eanCodeElectricity: string | null
  eanCodeGas: string | null
  gridAccessPointElectricity: GridAccessPoint | null
  gridAccessPointGas: GridAccessPoint | null
  isSameAddress: boolean | null
  isIdentified: boolean
}
