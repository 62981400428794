
















import BaseStep from './BaseStep'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import { EnergySelection } from '@/models'
import EnergySelector from '@/components/inputs/energy/EnergySelector.vue'
import { helper } from '@ores/vue-library'

@Component({
  name: 'old-address-energy-step',
  components: { EnergySelector },
})
export default class OldAddressEnergyStep extends BaseStep {
  //#region [Data]
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null
  @State('energySelection', { namespace: 'protectedCustomerRequest/oldAddressEnergy' })
  public readonly energySelectionState!: EnergySelection | null

  public energySelection: EnergySelection | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'protectedCustomerRequest/oldAddressEnergy' })
  public store!: (value: EnergySelection | null) => void
  //#endregion

  //#region [Computed]
  public get energies(): string[] {
    return ['electricityAndGas', 'electricity', 'gas']
  }

  //#endregion

  //#region [Method]
  public async mounted() {
    if (this.isCustomerState === 0) {
      if (this.isBack) {
        await this.previous()
      } else {
        this.setIsBack(false)
        await this.next()
      }
    } else {
      this.setIsBack(false)
      this.energySelection = helper.clone(this.energySelectionState)
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepOldAddressEnergy_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        IsCustomer: (this.isCustomerState === 1).toString()
      })
    }

    this.store(this.energySelection)
  }

  //#endregion
}
