














































































import CollapseInfo from '@/components/display/CollapseInfo.vue'
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import { EnergySelection, GridAccessPoint } from '@/models'
import { State } from 'vuex-class'
import { ConsumptionAddress } from '@/models/common'
import { chatService } from '@/services'

@Component({
  components: {
    CollapseInfo,
  },
})
export default class IdentificationConfirmation extends Vue {
  //#region [Data]
  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null
  @State('gridAccessPointElectricity', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointElectricityState!: GridAccessPoint | null
  @State('gridAccessPointGas', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointGasState!: GridAccessPoint | null
  @State('isSameAddress', { namespace: 'protectedCustomerRequest/identification' })
  public isSameAddress!: boolean | null
  //#endregion

  //#region [Computed]
  public get electricityConsumptionAddress(): string | null {
    return this.gridAccessPointElectricityState ?
      this.formatConsumptionAddress(this.gridAccessPointElectricityState.consumptionAddress) :
      null
  }

  public get gasConsumptionAddress(): string | null {
    return this.gridAccessPointGasState ?
      this.formatConsumptionAddress(this.gridAccessPointGasState.consumptionAddress) :
      null
  }

  //#endregion

  //#region [Method]
  public openChat() {
    chatService.openChat()
  }

  public formatConsumptionAddress(address: ConsumptionAddress): string {
    let str = '' + address.streetName
    if (address.houseNumber) {
      str += ' ' + address.houseNumber
    }
    if (address.complement) {
      str += ' ' + address.complement
    }
    if (address.complementBox) {
      str += ' ' + address.complementBox
    }
    if (address.floorNumber) {
      str += ` (${this.$t(`protectedCustomerRequest.identification.floor`).toString()}: ${address.floorNumber})`
    }
    str += ', ' + address.zipCode
    str += ' ' + address.cityName
    return str
  }

  //#endregion
}
