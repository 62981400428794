











import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import RegisterBox from '@/components/inputs/protected-customer-request/meter-reading/RegisterBox.vue'
import { Meter } from '@/models/protected-customer-request'

@Component({
  components: {
    RegisterBox,
  },
})
export default class MeterBox extends Vue {
  @Prop({ required: true }) public readonly value!: Meter
  @Prop({ type: Boolean, required: true }) public readonly isGas!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean

  public setIndexValue(value: string, idx: number): void {
    if (this.readonly) {
      return
    }

    this.value.registerInfos[idx].value = value
    this.$emit('input', this.value)
  }
}
