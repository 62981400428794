








































import BaseStep from './BaseStep'
import Component from 'vue-class-component'
import { FileInfo } from '@/models'
import { Mutation, State } from 'vuex-class'
import { FileStatus } from '@ores/vue-library'
import { helper } from '@/services'

@Component({
  name: 'attestation-step',
})
export default class AttestationStep extends BaseStep {
  //#region [Data]
  @State('attachments', { namespace: 'protectedCustomerRequest/attestation' })
  public attachmentsState!: FileInfo[]
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null

  public attachments: FileInfo[] = []
  //#endregion

  //#region [Mutations]
  @Mutation('STORE', { namespace: 'protectedCustomerRequest/attestation' })
  private store!: (index: FileInfo[]) => void
  //#endregion

  //#region [Computed]
  public get fileInError(): boolean {
    return this.attachments.some((v) => v.error !== null)
  }

  //#endregion

  //#region [Computed]
  public get required(): boolean {
    return this.isCustomerState === 0 ? this.attachments.length === 0 : false
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.attachmentsState !== []) {
      this.attachments = helper.clone(this.attachmentsState)
    }
    this.setIsBack(false)
  }

  public async uploadFiles(formData: FormData): Promise<{ data: FileStatus[] }> {
    this.$validatorObs.reset()
    return await this.$api.post<FileStatus[]>(
      '/api/Attachments/protected-customer-request?requestId=' + this.requestId,
      formData,
    )
  }

  public async deleteFiles(files: { data: string[] }): Promise<void> {
    return await this.$api.delete('/api/Attachments/protected-customer-request?requestId=' + this.requestId, files)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepAttestation_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        HasAttachments: (this.attachments.length > 0).toString()
      })
    }

    this.store(this.attachments.filter((a) => a.error === null))
  }

  //#endregion
}
