
















































































import { Emit, Vue, Watch } from 'vue-property-decorator'
import ZipCodeLocalitySelector from '@/components/inputs/address/ZipCodeLocalitySelector.vue'
import ConnectionPointArea from '@/components/inputs/address/ConnectionPointArea.vue'
import CollapseInfo from '@/components/display/CollapseInfo.vue'
import Component from 'vue-class-component'
import { EnergySelection, SubCity } from '@/models'
import { Mutation, State } from 'vuex-class'
import { chatService, helper } from '@/services'
import {
  GetElectricityAndGasGridAccessPointsByEanQuery,
  GetElectricityAndGasGridAccessPointsByEanResult,
  GridAccessPoint,
  LoginQuery,
} from '@/models/protected-customer-request'

@Component({
  name: 'identification',
  components: {
    ZipCodeLocalitySelector,
    ConnectionPointArea,
    CollapseInfo,
  },
})
export default class Identification extends Vue {
  //#region [Data]
  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null

  @State('subCity', { namespace: 'protectedCustomerRequest/identification' })
  public subCityState!: SubCity | null
  @State('eanCodeElectricity', { namespace: 'protectedCustomerRequest/identification' })
  public eanCodeElectricityState!: string | null
  @State('eanCodeGas', { namespace: 'protectedCustomerRequest/identification' })
  public eanCodeGasState!: string | null
  @State('gridAccessPointElectricity', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointElectricityState!: GridAccessPoint | null
  @State('gridAccessPointGas', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointGasState!: GridAccessPoint | null

  public subCity: SubCity | null = null
  public eanCodeElectricity: string | null = null
  public eanCodeGas: string | null = null

  public isIdentified: boolean = false
  public showErrorMessage: boolean = false

  public loading: boolean = false
  public recaptchaKey: number = 0
  public recaptchaToken: string | null = null

  public whereFoundEanModalOpen: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_IDENTIFICATION_INFO', { namespace: 'protectedCustomerRequest/identification' })
  public storeIdentificationInfo!: (value: {
    subCity: SubCity | null,
    eanCodeElectricity: string | null,
    eanCodeGas: string | null
  }) => void
  @Mutation('STORE_GRID_ACCESS_POINT_INFO', { namespace: 'protectedCustomerRequest/identification' })
  public storeGridAccessPointInfo!: (value: GetElectricityAndGasGridAccessPointsByEanResult | null) => void
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  public clearApiErrors!: () => void
  @Mutation('STORE_BEARER', { namespace: 'protectedCustomerRequest' })
  public storeToken!: (value: string) => void
  //#endregion


  //#region [Computed]
  public get isIdentifiedAndCanContinue(): boolean {
    return this.isIdentified &&
      (this.eanCodeGas === null || this.gridAccessPointGasState !== null) &&
      (this.eanCodeElectricity === null || this.gridAccessPointElectricityState !== null)
  }

  public get computeIsIdentified(): boolean {
    return !this.eanGasNotFound && !this.eanElectricityNotFound
  }

  public get eanElectricityNotFound(): boolean {
    if (this.energySelectionState?.electricityAndGas === true || this.energySelectionState?.electricity === true) {
      return this.gridAccessPointElectricityState === null
    }

    return false
  }

  public get eanGasNotFound(): boolean {
    if (this.energySelectionState?.electricityAndGas === true || this.energySelectionState?.gas === true) {
      return this.gridAccessPointGasState === null
    }

    return false
  }

  //#endregion

  //#region [Watch]
  @Watch('eanCodeElectricity')
  public onEanCodeElectricityChange(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.eanCodeElectricityState) {
      // User input is the same as last identified EAN
      this.isIdentified = this.computeIsIdentified
    } else {
      this.isIdentified = false
    }
  }

  @Watch('eanCodeGas')
  public onEanCodeGasChange(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.eanCodeGasState) {
      // User input is the same as last identified EAN
      this.isIdentified = this.computeIsIdentified
    } else {
      this.isIdentified = false
    }
  }

  @Watch('eanCodeElectricityState')
  @Watch('eanCodeGasState')
  public onEanCodeStateChange(value: string | null) {
    if (!value) {
      this.isIdentified = false
    }
  }

  @Watch('isIdentified')
  @Emit('isIdentified')
  public onIsIdentifiedChange(value: boolean): boolean | null {
    return value
  }

  @Watch('isIdentifiedAndCanContinue', { immediate: true })
  @Emit('canContinue')
  public isIdentifiedAndCanContinueChange(value: boolean): boolean | null {
    return value
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.$nextTick(() => {
      this.subCity = helper.clone(this.subCityState)
    })
    this.eanCodeElectricity = helper.clone(this.eanCodeElectricityState)
    this.eanCodeGas = helper.clone(this.eanCodeGasState)
    this.isIdentified = this.computeIsIdentified
  }

  public async getGridAccessPointsInfo(): Promise<GetElectricityAndGasGridAccessPointsByEanResult> {
    this.eanCodeElectricity = this.eanCodeElectricity?.trim() ?? null
    this.eanCodeGas = this.eanCodeGas?.trim() ?? null

    const loginPayload: LoginQuery = {
      eanElectricity: this.eanCodeElectricity,
      eanGas: this.eanCodeGas,
    }

    const loginResponse = await this.$api.post<string>(
      `protected-customer/login`,
      loginPayload,
      {
        headers: {
          'g-recaptcha-response': this.recaptchaToken,
        },
      },
    )
    if (loginResponse.data) {
      this.storeToken(loginResponse.data)
      sessionStorage.setItem('token', loginResponse.data)
    }

    const payload: GetElectricityAndGasGridAccessPointsByEanQuery = {
      eanElectricity: this.eanCodeElectricity,
      eanGas: this.eanCodeGas,
    }

    const response = await this.$api.post<GetElectricityAndGasGridAccessPointsByEanResult>(
      `protected-customer/ean-identification`,
      payload,
      {
        headers: {
          Authorization: loginResponse.data
        }
      },
    )
    return response.data
  }

  public async identify() {
    if (await this.$validatorObs.validate()) {
      try {
        this.loading = true

        const gridAccessPoints = await this.getGridAccessPointsInfo()

        this.storeGridAccessPointInfo(gridAccessPoints)
        this.storeIdentificationInfo({
          subCity: this.subCity,
          eanCodeElectricity: this.eanCodeElectricity?.trim() ?? null,
          eanCodeGas: this.eanCodeGas?.trim() ?? null,
        })

        this.loading = false
        this.isIdentified = this.computeIsIdentified
        this.showErrorMessage = true

        if (!this.isIdentifiedAndCanContinue) {
          // Refresh the captcha for next identification to work
          this.recaptchaKey += 1
        }
      } catch (err) {
        this.loading = false
        this.isIdentified = false
        this.storeGridAccessPointInfo(null)
      }
    }
  }

  public openChat() {
    chatService.openChat()
  }

  //#endregion
}
