var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lib__form__main"},[_c('ValidationObserver',{ref:"validator",staticClass:"lib__form__main__content",attrs:{"tag":"div"}},[_c('header',{staticClass:"lib__form__main__header"},[_c('h2',{staticClass:"lib__h2",domProps:{"innerHTML":_vm._s(_vm.$t('protectedCustomerRequest.customer.title'))}})]),_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"lib__field",class:[failed && 'lib__field--error', passed && 'lib__field--valid']},[_c('div',{staticClass:"lib__tiles lib__tiles--inline"},[_c('TileRadio',{key:1,attrs:{"item":1,"label":_vm.$t('protectedCustomerRequest.customer.isCustomer'),"group":"isCustomer"},model:{value:(_vm.isCustomer),callback:function ($$v) {_vm.isCustomer=$$v},expression:"isCustomer"}}),_c('TileRadio',{key:0,attrs:{"item":0,"label":_vm.$t('protectedCustomerRequest.customer.isNotCustomer'),"group":"isCustomer"},model:{value:(_vm.isCustomer),callback:function ($$v) {_vm.isCustomer=$$v},expression:"isCustomer"}})],1),_c('error-message',{attrs:{"error":errors[0]}})],1)]}}])}),(_vm.isCustomer === 1)?[_c('header',{staticClass:"lib__form__main__header"},[_c('h2',{staticClass:"lib__h2",domProps:{"innerHTML":_vm._s(_vm.$t('protectedCustomerRequest.customer.wantStayCustomer'))}})]),_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"lib__field",class:[failed && 'lib__field--error', passed && 'lib__field--valid']},[_c('div',{staticClass:"lib__tiles lib__tiles--inline"},[_c('TileRadio',{key:1,attrs:{"item":1,"label":_vm.$t('common.yes'),"group":"stayCustomer"},model:{value:(_vm.stayCustomer),callback:function ($$v) {_vm.stayCustomer=$$v},expression:"stayCustomer"}}),_c('TileRadio',{key:0,attrs:{"item":0,"label":_vm.$t('common.no'),"group":"stayCustomer"},model:{value:(_vm.stayCustomer),callback:function ($$v) {_vm.stayCustomer=$$v},expression:"stayCustomer"}})],1),_c('error-message',{attrs:{"error":errors[0]}})],1)]}}],null,false,3881395403)})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }