























import BaseStep from './BaseStep'
import Identification from '@/components/inputs/protected-customer-request/identification/Identification.vue'
import IdentificationConfirmation from '@/components/inputs/protected-customer-request/identification/IdentificationConfirmation.vue'
import Component from 'vue-class-component'
import { EnergySelection } from '@/models'
import { State } from 'vuex-class'

@Component({
  name: 'identification-step',
  components: {
    Identification,
    IdentificationConfirmation,
  },
})
export default class IdentificationStep extends BaseStep {
  //#region [Data]
  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null
  @State('eanCodeElectricity', { namespace: 'protectedCustomerRequest/identification' })
  public eanCodeElectricityState!: string | null
  @State('eanCodeGas', { namespace: 'protectedCustomerRequest/identification' })
  public eanCodeGasState!: string | null

  public isIdentified: boolean | null = null
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepIdentification_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        EanCodeElectricity: this.eanCodeElectricityState ?? '',
        EanCodeGas: this.eanCodeGasState ?? '',
      })
    }
  }

  //#endregion
}
