





























import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { Register } from '@/models/protected-customer-request'
import { RegisterDirection } from '@/models/common'

@Component
export default class RegisterBox extends Vue {
  @Prop({ required: true }) public readonly value!: Register
  @Prop({ type: Boolean, required: true }) public readonly isGas!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean


  get meterRegisterDirection(): string {
    return this.value.direction === RegisterDirection.Production ?
      this.$t('common.meterRegister.production').toString() :
      this.$t('common.meterRegister.consumption').toString()
  }

  get gasMeterType(): string {
    switch (this.value.code) {
      case 'ANALOG': return this.$tc('common.meterRegister.analog')
      case 'DIGITAL': return this.$tc('common.meterRegister.digital')
      default: return ''
    }
  }

  get isNight(): boolean {
    return this.value.isNight
  }

  get index(): string {
    return this.value.value ?? ''
  }

  set index(val: string) {
    if (this.readonly) {
      return
    }

    this.$emit('input', val)
  }
}
