















































































































































































































































































































































































































































































































































































































































































import BaseStep from './BaseStep'
import Component from 'vue-class-component'
import { State } from 'vuex-class'
import { ContactInfo, EnergySelection, FileInfo, OwnerInfo } from '@/models'
import { Prop } from 'vue-property-decorator'
import { contactService, dateService } from '@/services'
import {
  Energy,
  GridAccessPoint,
  OtherContact,
  Owner,
  PrimaryContact,
  SendProtectedCustomerRequestCommand,
} from '@/models/protected-customer-request'
import { ConsumptionAddress } from '@/models/common'
import { ContactAddress } from '@/models/common/contact'


@Component({
  name: 'summary-step',
})
export default class SummaryStep extends BaseStep {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  @State('lang')
  public readonly lang!: string

  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null

  @State('stayCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public stayCustomerState!: number | null

  @State('energySelection', { namespace: 'protectedCustomerRequest/energy' })
  public readonly energySelectionState!: EnergySelection | null

  @State('isProsumer', { namespace: 'protectedCustomerRequest/prosumer' })
  public isProsumerState!: number | null

  @State('gridAccessPointElectricity', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointElectricityState!: GridAccessPoint | null

  @State('gridAccessPointGas', { namespace: 'protectedCustomerRequest/identification' })
  public gridAccessPointGasState!: GridAccessPoint | null

  @State('isSameAddress', { namespace: 'protectedCustomerRequest/identification' })
  public isSameAddress!: boolean | null

  @State('movingDate', { namespace: 'protectedCustomerRequest/meterReading' })
  public readonly movingDateState!: Date | null

  @State('energySelection', { namespace: 'protectedCustomerRequest/oldAddressEnergy' })
  public readonly oldAddressEnergySelectionState!: EnergySelection | null

  @State('gridAccessPointElectricity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public oldAddressGridAccessPointElectricityState!: GridAccessPoint | null

  @State('gridAccessPointGas', { namespace: 'protectedCustomerRequest/oldAddress' })
  public oldAddressGridAccessPointGasState!: GridAccessPoint | null

  @State('isSameAddress', { namespace: 'protectedCustomerRequest/oldAddress' })
  public oldAddressIsSameAddress!: boolean | null

  @State('movingDate', { namespace: 'protectedCustomerRequest/oldAddressMeterReading' })
  public readonly movingOutDateState!: Date | null

  @State('attachments', { namespace: 'protectedCustomerRequest/attestation' })
  public attachmentsState!: FileInfo[]

  @State('contactInfo', { namespace: 'protectedCustomerRequest/contactInfo' })
  public contactInfoState!: ContactInfo | null

  @State('isSameAsClientInfo', { namespace: 'protectedCustomerRequest/owner' })
  public readonly isSameAsClientInfoState!: boolean | null

  @State('isOwnerInfoKnown', { namespace: 'protectedCustomerRequest/owner' })
  public readonly isInfoKnownState!: boolean | null

  @State('ownerInfos', { namespace: 'protectedCustomerRequest/owner' })
  public readonly ownerInfosState!: OwnerInfo

  //#region [Mutation]
  public get movingDate(): string {
    if (this.movingDateState) {
      return dateService.displayDateText(this.movingDateState)
    }
    return ''
  }

  public get movingOutDate(): string {
    if (this.movingOutDateState) {
      return dateService.displayDateText(this.movingOutDateState)
    }
    return ''
  }

  //#endregion

  //#region [Computed]
  get isElectricityRequest(): boolean {
    return this.energySelectionState!.electricity || this.energySelectionState!.electricityAndGas
  }

  get contactBirthDate(): string | null {
    return this.contactInfoState?.contactBase?.birthdate ?
      dateService.displayDate(this.contactInfoState?.contactBase?.birthdate) : null
  }

  get formattedOtherAddress(): string {
    return this.contactInfoState?.otherAddress ?
      contactService.getFormattedAddress(this.contactInfoState?.otherAddress) : ''
  }

  get formattedOwnerAddress(): string {
    return this.ownerInfosState?.address ?
      contactService.getFormattedAddress(this.ownerInfosState?.address) : ''
  }

  get formattedOtherContactAddress(): string {
    return this.contactInfoState?.otherContact?.address ?
      contactService.getFormattedAddress(this.contactInfoState?.otherContact?.address) : ''
  }

  public get electricityConsumptionAddress(): string | null {
    return this.gridAccessPointElectricityState ?
      this.formatConsumptionAddress(this.gridAccessPointElectricityState.consumptionAddress) :
      null
  }

  public get gasConsumptionAddress(): string | null {
    return this.gridAccessPointGasState ?
      this.formatConsumptionAddress(this.gridAccessPointGasState.consumptionAddress) :
      null
  }

  public get oldAddressElectricityConsumptionAddress(): string | null {
    return this.oldAddressGridAccessPointElectricityState ?
      this.formatConsumptionAddress(this.oldAddressGridAccessPointElectricityState.consumptionAddress) :
      null
  }

  public get oldAddressGasConsumptionAddress(): string | null {
    return this.oldAddressGridAccessPointGasState ?
      this.formatConsumptionAddress(this.oldAddressGridAccessPointGasState.consumptionAddress) :
      null
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
  }

  public computeStepNumber(stepNumber: number): string {
    let computedStepNumber = stepNumber
    if (stepNumber > 3) {
      if (!this.isElectricityRequest) {
        computedStepNumber--
      }
    }

    if (stepNumber > 8) {
      if (this.isCustomerState === 0) {
        computedStepNumber -= 3
      }
    }

    return computedStepNumber.toString()
  }

  public formatConsumptionAddress(address: ConsumptionAddress): string {
    let str = '' + address.streetName
    if (address.houseNumber) {
      str += ' ' + address.houseNumber
    }
    if (address.complement) {
      str += ' ' + address.complement
    }
    if (address.complementBox) {
      str += ' ' + address.complementBox
    }
    if (address.floorNumber) {
      str += ` (${this.$t(`protectedCustomerRequest.identification.floor`).toString()}: ${address.floorNumber})`
    }
    str += ', ' + address.zipCode
    str += ' ' + address.cityName
    return str
  }

  public goToStep(step: number): void {
    this.setNextButtonStatus(true)
    this.$emit('goToStep', step)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {

      const header = { headers: this.httpHeaders }

      const contactInfo = this.contactInfoState!

      const primaryContact: PrimaryContact = {
        firstName: contactInfo.contactBase!.firstName ?? '',
        lastName: contactInfo.contactBase!.lastName ?? '',
        email: contactInfo.contactBase!.email ?? '',
        civility: contactInfo.contactBase!.civility!,
        mobile: contactInfo.contactBase!.mobile,
        phone: contactInfo.contactBase!.phone,
        bankAccountBic: contactInfo.contactBase!.bankAccountBic,
        bankAccountIban: contactInfo.contactBase!.bankAccountIban,
        nationalId: contactInfo.contactBase!.nationalId,
        customerRef: contactInfo.contactBase!.customerRef,
        birthDate: contactInfo.contactBase!.birthdate,
      }

      let otherAddress: ContactAddress | null = null
      if (contactInfo.useOtherAddress && contactInfo.otherAddress !== null) {
        otherAddress = {
          zipCode: contactInfo.otherAddress.subCity!.zipCode,
          subCity: contactInfo.otherAddress.subCity!.cityName,
          street: contactInfo.otherAddress.streetSubCity!.name,
          country: contactInfo.otherAddress.country!,
          houseNumber: contactInfo.otherAddress.houseNumber!,
          houseBox: contactInfo.otherAddress.houseBox,
        }
      }

      let otherContact: OtherContact | null = null
      if (contactInfo.addOtherContact && contactInfo.otherContact !== null) {
        otherContact = {
          firstName: contactInfo.otherContact.firstName ?? '',
          lastName: contactInfo.otherContact.lastName ?? '',
          email: contactInfo.otherContact.email ?? '',
          job: contactInfo.otherContact.job ?? '',
          civility: contactInfo.otherContact.civility!,
          mobile: contactInfo.otherContact.mobile,
          phone: contactInfo.otherContact.phone,
          address: {
            zipCode: contactInfo.otherContact.address.subCity!.zipCode,
            subCity: contactInfo.otherContact.address.subCity!.cityName,
            street: contactInfo.otherContact.address.streetSubCity!.name,
            country: contactInfo.otherContact.address.country!,
            houseNumber: contactInfo.otherContact.address.houseNumber!,
            houseBox: contactInfo.otherContact.address.houseBox,
          },
        }
      }

      let owner: Owner | null = null
      if (this.isSameAsClientInfoState === false &&
        this.isInfoKnownState === true &&
        this.ownerInfosState.contactBaseInfo) {
        owner = {
          firstName: this.ownerInfosState.contactBaseInfo.firstName ?? '',
          lastName: this.ownerInfosState.contactBaseInfo.lastName ?? '',
          email: this.ownerInfosState.contactBaseInfo.email ?? '',
          job: this.ownerInfosState.contactBaseInfo.job ?? '',
          civility: this.ownerInfosState.contactBaseInfo.civility!,
          mobile: this.ownerInfosState.contactBaseInfo.mobile,
          phone: this.ownerInfosState.contactBaseInfo.phone,
          companyName: this.ownerInfosState.contactBaseInfo.companyName,
          companyType: this.ownerInfosState.contactBaseInfo.companyType,
          contactType: this.ownerInfosState.contactBaseInfo.contactType!,
          vatNumber: this.ownerInfosState.contactBaseInfo.vatNumber,
          address: {
            zipCode: this.ownerInfosState.address.subCity!.zipCode,
            subCity: this.ownerInfosState.address.subCity!.cityName,
            street: this.ownerInfosState.address.streetSubCity!.name,
            country: this.ownerInfosState.address.country!,
            houseNumber: this.ownerInfosState.address.houseNumber!,
            houseBox: this.ownerInfosState.address.houseBox,
          },
        }
      }


      const requestData: SendProtectedCustomerRequestCommand = {
        webReference: this.requestId,
        language: this.lang,
        isCustomer: this.isCustomerState === 1,
        stayCustomer: this.isCustomerState === 1 ? this.stayCustomerState === 1 : null,
        energy: this.energySelectionState!.electricity ? Energy.Electricity :
          this.energySelectionState!.gas ? Energy.Gas : Energy.ElectricityAndGas,
        isProsumer: this.isProsumerState !== null ? this.isProsumerState === 1 : null,
        movingDate: this.movingDateState!,
        electricityGridAccessPoint: this.gridAccessPointElectricityState,
        gasGridAccessPoint: this.gridAccessPointGasState,
        oldAddressEnergy: this.isCustomerState === 0 ? null : this.oldAddressEnergySelectionState!.electricity ?
          Energy.Electricity : this.oldAddressEnergySelectionState!.gas ? Energy.Gas : Energy.ElectricityAndGas,
        movingOutDate: this.movingOutDateState,
        oldAddressElectricityGridAccessPoint: this.oldAddressGridAccessPointElectricityState,
        oldAddressGasGridAccessPoint: this.oldAddressGridAccessPointGasState,
        attachments: this.attachmentsState.map((a) => a.name),
        requestorInfos: primaryContact,
        addOtherAddress: contactInfo.useOtherAddress!,
        otherAddress,
        addOtherContactPerson: contactInfo.addOtherContact!,
        otherContactPerson: otherContact,
        isOwnerSameAddress: this.isSameAsClientInfoState!,
        isOwnerInfoKnown: this.isSameAsClientInfoState === true ? null : this.isInfoKnownState!,
        ownerInfos: owner,
      }

      await this.$api.post('protected-customer', requestData, header)

      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepSummary_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        IsCustomer: (this.isCustomerState === 1).toString(),
        StayCustomer: (this.stayCustomerState === 1).toString(),
        Energy: this.energySelectionState!.electricity ? Energy.Electricity :
          this.energySelectionState!.gas ? Energy.Gas : Energy.ElectricityAndGas,
        IsProsumer: this.isProsumerState !== null ? (this.isProsumerState === 1).toString() : '',
        HasAttachments: (this.attachmentsState.length > 0).toString(),
        AddOtherAddress: contactInfo.useOtherAddress!.toString(),
        AddOtherContact: contactInfo.addOtherContact!.toString(),
      })
    }
  }

  //#endregion
}
