



















import BaseStep from './BaseStep'
import GetGridAccessPointInfo, {
  GetGridAccessPointInfoModel,
} from '@/components/inputs/protected-customer-request/customer/GetGridAccessPointInfo.vue'
import Component from 'vue-class-component'
import { State } from 'vuex-class'
import { EnergySelection, SubCity } from '@/models'
import { GridAccessPoint } from '@/models/protected-customer-request'

@Component({
  name: 'old-address-step',
  components: {
    GetGridAccessPointInfo,
  },
})
export default class OldAddress extends BaseStep {
  //#region [Data]
  @State('isCustomer', { namespace: 'protectedCustomerRequest/customer' })
  public isCustomerState!: number | null

  @State('energySelection', { namespace: 'protectedCustomerRequest/oldAddressEnergy' })
  public readonly energySelectionState!: EnergySelection | null
  @State('subCity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public subCityState!: SubCity | null
  @State('eanCodeElectricity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public eanCodeElectricityState!: string | null
  @State('eanCodeGas', { namespace: 'protectedCustomerRequest/oldAddress' })
  public eanCodeGasState!: string | null
  @State('gridAccessPointElectricity', { namespace: 'protectedCustomerRequest/oldAddress' })
  public gridAccessPointElectricityState!: GridAccessPoint | null
  @State('gridAccessPointGas', { namespace: 'protectedCustomerRequest/oldAddress' })
  public gridAccessPointGasState!: GridAccessPoint | null
  @State('isSameAddress', { namespace: 'protectedCustomerRequest/oldAddress' })
  public isSameAddress!: boolean | null

  public getGridAccessPointInfoModel: GetGridAccessPointInfoModel = {
    eanCodeElectricity: null,
    eanCodeGas: null,
    gridAccessPointElectricity: null,
    gridAccessPointGas: null,
    isSameAddress: null,
    subCity: null,
    isIdentified: false
  }
  //#endregion

  //#region [Watch]
  //#endregion

  //#region [Method]
  public async mounted() {
    if (this.isCustomerState === 0) {
      if (this.isBack) {
        await this.previous()
      } else {
        this.setIsBack(false)
        await this.next()
      }
    } else {
      this.setIsBack(false)

      this.getGridAccessPointInfoModel = {
        eanCodeElectricity: this.eanCodeElectricityState,
        eanCodeGas: this.eanCodeGasState,
        gridAccessPointElectricity: this.gridAccessPointElectricityState,
        gridAccessPointGas: this.gridAccessPointGasState,
        isSameAddress: this.isSameAddress,
        subCity: this.subCityState,
        isIdentified: false
      }
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProtectedCustomerRequest_StepOldAddress_Validated', {
        Segment: 'ProtectedCustomerRequest',
        WebRef: this.requestId,
        IsCustomer: (this.isCustomerState === 1).toString(),
        EanCodeElectricity: this.eanCodeElectricityState ?? '',
        EanCodeGas: this.eanCodeGasState ?? '',
      })
    }
  }

  //#endregion
}
